var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailInfo",
              attrs: { title: "LBL0002372" },
            },
            [
              _c(
                "template",
                { slot: "card-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.standardInfo.reportTypeCd === "IRC0000005" ||
                      _vm.standardInfo.reportTypeCd === "IRC0000001"
                        ? _c("font", { attrs: { color: "#C10015" } }, [
                            _vm._v(
                              " " + _vm._s(_vm.$message("MSG0000705")) + " "
                            ),
                          ])
                        : _vm._e(),
                      _c("c-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.disabled,
                            expression: "!disabled",
                          },
                        ],
                        attrs: {
                          isSubmit: _vm.isComplete,
                          url: _vm.completeUrl,
                          param: _vm.accidentInfo,
                          mappingType: _vm.completeType,
                          label: "LBL0002374",
                          icon: "check",
                        },
                        on: {
                          beforeAction: _vm.completeAccident,
                          btnCallback: _vm.completeCallback,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-label-text", {
                      attrs: {
                        title: "LBL0002305",
                        value: _vm.standardInfo.accidentNo,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-label-text", {
                      attrs: {
                        title: "LBL0002306",
                        value: _vm.standardInfo.accidentName,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-label-text", {
                      attrs: {
                        title: "LBL0002301",
                        value: _vm.standardInfo.accidentKindName,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-label-text", {
                      attrs: {
                        title: "LBL0002300",
                        value: _vm.standardInfo.accidentGradeName,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-label-text", {
                      attrs: {
                        title: "LBL0002309",
                        value: _vm.standardInfo.occurrenceDate,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-label-text", {
                      attrs: {
                        title: "LBL0002302",
                        value: _vm.standardInfo.occurrenceDeptName,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-label-text", {
                      attrs: {
                        title: "LBL0002375",
                        value: _vm.standardInfo.occurrenceModeLargeName,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-label-text", {
                      attrs: {
                        title: "LBL0002376",
                        value: _vm.standardInfo.occurrenceModeSmallName,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { title: "LBL0002391" },
            },
            [
              _c(
                "template",
                { slot: "card-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      !_vm.disabled
                        ? _c("c-btn", {
                            attrs: {
                              url: _vm.updateUrl,
                              isSubmit: _vm.isSave,
                              param: _vm.accidentInfo,
                              mappingType: "PUT",
                              label: "LBLSAVE",
                              icon: "save",
                            },
                            on: {
                              beforeAction: _vm.saveAccident,
                              btnCallback: _vm.saveCallback,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  {
                    staticClass: "col-xs-9 col-sm-9 col-md-4 col-lg-4 col-xl-4",
                  },
                  [
                    _c("c-textarea", {
                      attrs: {
                        editable: _vm.editable,
                        disabled: _vm.disabled,
                        rows: 5,
                        label: "LBL00001556",
                        name: "shortPlan",
                      },
                      model: {
                        value: _vm.accidentInfo.shortPlan,
                        callback: function ($$v) {
                          _vm.$set(_vm.accidentInfo, "shortPlan", $$v)
                        },
                        expression: "accidentInfo.shortPlan",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4",
                  },
                  [
                    _c("c-textarea", {
                      attrs: {
                        editable: _vm.editable,
                        disabled: _vm.disabled,
                        rows: 5,
                        label: "LBL00001557",
                        name: "longPlan",
                      },
                      model: {
                        value: _vm.accidentInfo.longPlan,
                        callback: function ($$v) {
                          _vm.$set(_vm.accidentInfo, "longPlan", $$v)
                        },
                        expression: "accidentInfo.longPlan",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4",
                  },
                  [
                    _c("c-textarea", {
                      attrs: {
                        editable: _vm.editable,
                        disabled: _vm.disabled,
                        rows: 5,
                        label: "LBL00001558",
                        name: "managerOpinion",
                      },
                      model: {
                        value: _vm.accidentInfo.managerOpinion,
                        callback: function ($$v) {
                          _vm.$set(_vm.accidentInfo, "managerOpinion", $$v)
                        },
                        expression: "accidentInfo.managerOpinion",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-table",
            {
              ref: "tableImpr",
              attrs: {
                title: "개선 목록",
                columns: _vm.gridImpr.columns,
                data: _vm.accidentInfo.accidentImproveModelList,
                gridHeight: _vm.gridImpr.height,
                merge: _vm.gridImpr.merge,
                selection: "multiple",
                rowKey: "causesNo",
                usePaging: false,
                isExcelDown: false,
                filtering: false,
                columnSetting: false,
                editable: _vm.editable && !_vm.disabled,
              },
              scopedSlots: _vm._u([
                {
                  key: "customArea",
                  fn: function ({ props, col }) {
                    return [
                      col.name === "customCol"
                        ? [
                            _c(_vm.imprComponent, {
                              tag: "component",
                              attrs: {
                                col: col,
                                props: props,
                                inputEditable: _vm.editable && !_vm.disabled,
                                isImmShow: true,
                                requestContentsCols: _vm.requestContentsCols,
                                tableKey: "causesNo",
                                ibmTaskTypeCd: "ITT0000040",
                                ibmTaskUnderTypeCd: "ITTU000065",
                              },
                              on: { imprChange: _vm.imprChange },
                            }),
                          ]
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable && !_vm.disabled
                        ? _c("c-btn", {
                            attrs: {
                              label: "LBLADD",
                              showLoading: false,
                              icon: "add",
                            },
                            on: { btnClicked: _vm.add },
                          })
                        : _vm._e(),
                      _vm.editable &&
                      !_vm.disabled &&
                      _vm.accidentInfo.accidentImproveModelList.length > 0
                        ? _c("c-btn", {
                            attrs: {
                              url: _vm.updateCauseUrl,
                              isSubmit: _vm.isSave2,
                              param: _vm.accidentInfo.accidentImproveModelList,
                              mappingType: "PUT",
                              label: "LBLSAVE",
                              icon: "save",
                            },
                            on: {
                              beforeAction: _vm.saveAccident2,
                              btnCallback: _vm.saveCallback,
                            },
                          })
                        : _vm._e(),
                      _vm.editable &&
                      !_vm.disabled &&
                      _vm.accidentInfo.accidentImproveModelList.length > 0
                        ? _c("c-btn", {
                            attrs: {
                              label: "LBLREMOVE",
                              showLoading: false,
                              icon: "remove",
                            },
                            on: { btnClicked: _vm.removeImpr },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }