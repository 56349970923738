<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
      <c-table
        ref="grid1"
        title="작업관련 설비"
        :columns="grid1.columns"
        :data="grid1.data"
        :gridHeight="grid1.height"
        :editable="editable"
        :hideBottom="true"
        :usePaging="false"
        :isExcelDown="false"
        :filtering="false"
        :isFullScreen="true"
        :columnSetting="false"
        topBorderClass="topcolor-orange"
        selection="multiple"
        rowKey="equipmentCd"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn label="추가" icon="add" @btnClicked="addRow1" v-if="editable && Boolean(param.mdmSwsId)" />
            <c-btn label="삭제" icon="remove" @btnClicked="removeRow1" v-if="editable && Boolean(param.mdmSwsId)" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
      <c-table
        ref="grid2"
        title="작업관련 위치"
        :columns="grid2.columns"
        :data="grid2.data"
        :gridHeight="grid2.height"
        :editable="editable"
        :hideBottom="true"
        :usePaging="false"
        :isExcelDown="false"
        :filtering="false"
        :isFullScreen="true"
        :columnSetting="false"
        topBorderClass="topcolor-orange"
        selection="multiple"
        rowKey="funcLocationCd"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn label="추가" icon="add" @btnClicked="addRow2" v-if="editable && Boolean(param.mdmSwsId)" />
            <c-btn label="삭제" icon="remove" @btnClicked="removeRow2" v-if="editable && Boolean(param.mdmSwsId)" />
          </q-btn-group>
        </template>
      </c-table>
    </div> -->
    <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
      <c-table
        ref="grid3"
        title="작업관련 정비자재"
        :columns="grid3.columns"
        :data="grid3.data"
        :gridHeight="grid3.height"
        :editable="editable"
        :hideBottom="true"
        :usePaging="false"
        :isExcelDown="false"
        :filtering="false"
        :isFullScreen="true"
        :columnSetting="false"
        topBorderClass="topcolor-orange"
        selection="multiple"
        rowKey="materialCd"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn label="추가" icon="add" @btnClicked="addRow3" v-if="editable && Boolean(param.mdmSwsId)" />
            <c-btn label="삭제" icon="remove" @btnClicked="removeRow3" v-if="editable && Boolean(param.mdmSwsId)" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'work-standard-detail-equip',
  props: {
    param: {
      type: Object,
      default: () => ({
        mdmSwsId: '',
        plantCd: '',
        updateFlag: '',
        isRevision: false,
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '60%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      editable: false,
      grid1: {
        columns: [
          {
            name: 'equipmentTypeName',
            field: 'equipmentTypeName',
            label: '설비유형',
            align: 'center',
            sortable: false,
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '설비명',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
        height: '300px',
      },
      grid2: {
        columns: [
          {
            name: 'funcLocationName',
            field: 'funcLocationName',
            label: '기능위치',
            align: 'center',
            sortable: false,
          },
        ],
        data: [],
        height: '300px',
      },
      grid3: {
        columns: [
          {
            name: 'materialName',
            field: 'materialName',
            label: '품명',
            align: 'left',
            sortable: true,
          },
          {
            name: 'realStock',
            field: 'realStock',
            label: '재고량',
            type: 'cost',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'safetyStock',
            field: 'safetyStock',
            label: '안전 재고량',
            type: 'cost',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'ropStock',
            field: 'ropStock',
            label: '재주문점(ROP)',
            type: 'cost',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'materialUnitName',
            field: 'materialUnitName',
            label: '단위',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
        ],
        data: [],
        height: '300px',
      },
      listUrl1: '',
      saveUrl1: '',
      deleteUrl1: '',
      listUrl2: '',
      saveUrl2: '',
      deleteUrl2: '',
      listUrl3: '',
      saveUrl3: '',
      deleteUrl3: '',
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    'param.updateFlag'() {
      this.getList1(this.param.updateFlag.split('|')[0])
      this.getList2(this.param.updateFlag.split('|')[0])
      this.getList3(this.param.updateFlag.split('|')[0])
      if (this.param.updateFlag.split('|')[1] == 'true') {
        this.editable = false;
      } else {
        this.editable = this.$route.meta.editable;
      }
    }
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl1 = selectConfig.mdm.sws.equip.list.url;
      this.saveUrl1 = transactionConfig.mdm.sws.equip.save.url;
      this.deleteUrl1 = transactionConfig.mdm.sws.equip.delete.url;
      this.listUrl2 = selectConfig.mdm.sws.loc.list.url;
      this.saveUrl2 = transactionConfig.mdm.sws.loc.save.url;
      this.deleteUrl2 = transactionConfig.mdm.sws.loc.delete.url;
      this.listUrl3 = selectConfig.mdm.sws.material.list.url;
      this.saveUrl3 = transactionConfig.mdm.sws.material.save.url;
      this.deleteUrl3 = transactionConfig.mdm.sws.material.delete.url;
      this.getList1(this.param.mdmSwsId);
      this.getList2(this.param.mdmSwsId);
      this.getList3(this.param.mdmSwsId);
    },
    getList1(_mdmSwsId) {
      if (_mdmSwsId) {
        this.$http.url = this.listUrl1;
        this.$http.param = {mdmSwsId: _mdmSwsId};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid1.data = _result.data;
        },);
      }
    },
    getList2(_mdmSwsId) {
      if (_mdmSwsId) {
        this.$http.url = this.listUrl2;
        this.$http.param = {mdmSwsId: _mdmSwsId};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid2.data = _result.data;
        },);
      }
    },
    getList3(_mdmSwsId) {
      if (_mdmSwsId) {
        this.$http.url = this.listUrl3;
        this.$http.param = {mdmSwsId: _mdmSwsId};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid3.data = _result.data;
        },);
      }
    },
    addRow1() {
      this.popupOptions.title = '설비 검색'; // 설비 검색
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.param.plantCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/common/equip/equipPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEquipPopup;
    },
    closeEquipPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let saveData = [];
        this.$_.forEach(data, (item) => {
          if ( this.$_.findIndex(this.grid1.data, {equipmentCd: item.equipmentCd}) === -1 ) {
            saveData.push({
              mdmSwsId: this.param.mdmSwsId,  // 안전작업 표준 일련번호
              equipmentCd: item.equipmentCd,  // 작업관련 설비
              editFlag: 'C',
              regUserId: this.$store.getters.user.userId,
            });
          }
        });
        
        this.$http.url = this.saveUrl1;
        this.$http.type = 'POST';
        this.$http.param = saveData;
        this.$http.request(() => {
          this.getList1(this.param.mdmSwsId);
          window.getApp.$emit('ALERT', {
            title: '안내 ' /* 안내 */,
            message: '저장되었습니다.' ,
            type: 'success', // success / info / warning / error
          });
        });
      }
    },
    removeRow1() {
      let selectData = this.$refs['grid1'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl1);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid1.data = this.$_.reject(this.grid1.data, item);
              })
              this.$refs['grid1'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    // addRow2() {
    //   this.popupOptions.title = '기능위치 검색'; // 설비 검색
    //   this.popupOptions.param = {
    //     type: 'multiple',
    //     plantCd: this.param.plantCd,
    //   };
    //   this.popupOptions.target = () => import(`${'@/pages/common/location/locationPop.vue'}`);
    //   this.popupOptions.width = '40%';
    //   this.popupOptions.visible = true;
    //   this.popupOptions.closeCallback = this.closeLocPopup;
    // },
    // closeLocPopup(data) {
    //   this.popupOptions.target = null;
    //   this.popupOptions.visible = false;
    //   if (data && data.length > 0) {
    //     let saveData = [];
    //     this.$_.forEach(data, (item) => {
    //       if ( this.$_.findIndex(this.grid2.data, {funcLocationCd: item.funcLocationCd}) === -1 ) {
    //         saveData.push({
    //           mdmSwsId: this.param.mdmSwsId,  // 안전작업 표준 일련번호
    //           funcLocationCd: item.funcLocationCd,  // 작업관련 기능위치
    //           editFlag: 'C',
    //           regUserId: this.$store.getters.user.userId,
    //         });
    //       }
    //     });
        
    //     this.$http.url = this.saveUrl2;
    //     this.$http.type = 'POST';
    //     this.$http.param = saveData;
    //     this.$http.request(() => {
    //       this.getList2(this.param.mdmSwsId);
    //       window.getApp.$emit('ALERT', {
    //         title: '안내 ' /* 안내 */,
    //         message: '저장되었습니다.' ,
    //         type: 'success', // success / info / warning / error
    //       });
    //     });
    //   }
    // },
    removeRow2() {
      let selectData = this.$refs['grid2'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl2);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid2.data = this.$_.reject(this.grid2.data, item);
              })
              this.$refs['grid2'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addRow3() {
      this.popupOptions.title = '기능위치 검색'; // 설비 검색
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.param.plantCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/common/material/materialPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeMaterialPopup;
    },
    closeMaterialPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let saveData = [];
        this.$_.forEach(data, (item) => {
          if ( this.$_.findIndex(this.grid3.data, {materialCd: item.materialCd}) === -1 ) {
            saveData.push({
              mdmSwsId: this.param.mdmSwsId,  // 안전작업 표준 일련번호
              materialCd: item.materialCd,  // 작업관련 기능위치
              editFlag: 'C',
              regUserId: this.$store.getters.user.userId,
            });
          }
        });
        
        this.$http.url = this.saveUrl3;
        this.$http.type = 'POST';
        this.$http.param = saveData;
        this.$http.request(() => {
          this.getList3(this.param.mdmSwsId);
          window.getApp.$emit('ALERT', {
            title: '안내 ' /* 안내 */,
            message: '저장되었습니다.' ,
            type: 'success', // success / info / warning / error
          });
        });
      }
    },
    removeRow3() {
      let selectData = this.$refs['grid3'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl3);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid3.data = this.$_.reject(this.grid3.data, item);
              })
              this.$refs['grid3'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>